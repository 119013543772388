/* @import url("https://fonts.googleapis.com/css?family=Roboto"); */
.App {
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  height: auto;
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAElBMVEUAAAD8/vz08vT09vT8+vzs7uxH16TeAAAAAXRSTlMAQObYZgAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAuFJREFUOI0Vk+3NLiEIRG1B8ClAYAsQ2AIEt4D9ePtv5Xp/mZgYJ2fOFJKEfInkVWY2aglmQFkimRTV7MblYyVqD7HXyhKsSuPX12MeDhRHLtGvRG+P+B/S0Vu4OswR9tmvwNPyhdCDbVayJGads/WiUWcjCvCnruTBNHS9gmX2VzVbk7ZvB1gb1hkWFGl+A/n+/FowcO34U/XvKqZ/fHY+6vgRfU92XrOBUbGeeDfQmjWjdrK+frc6FdGReQhfSF5JvR29O2QrfNw1huTwlgsyXLo0u+5So82sgv7tsFZR2nxB6lXiquHrfD8nfYZ9SeT0LiuvSoVrxGY16pCNRZKqvwWsn5OHypPBELzohMCaRaa0ceTHYqe7X/gfJEEtKFbJpWoNqO+aS1cuTykGPpK5Ga48m6L3NefTr013KqYBQu929iP1oQ/7UwSR+i3zqruUmT84qmhzLpxyj7pr9kg7LKvqaXxZmdpn+6o8sHqSqojy02gU3U8q9PnpidiaLks0mbMYz+q2uVXsoBQ8bfURULYxRgZVYCHMv9F4OA7qxT2NPPpvGQ/sTDH2yznKh7E2AcErfcNsaIoN1izzbJiaY63x4QjUFdBSvDCvugPpu5xDny0jzEeuUQbcP1aGT9V90uixngTRLYNEIIZ6yOF1H8tm7rj2JxiefsVy53zGVy3ag5uuPsdufYOzYxLRxngKe7nhx3VAq54pmz/DK9/Q3aDam2Yt3hNXB4HuU87jKNd/CKZn77Qdn5QkXPfqSkhk7hGOXXB+7v09KbBbqdvxGqa0AqfK/atIrL2WXdAgXAJ43Wtwe/aIoacXezeGPMlhDOHDbSfHnaXsL2QzbT82GRwZuezdwcoWzx5pnOnGMUdHuiY7lhdyWzWiHnucLZQxYStMJbtcydHaQ6vtMbe0AcDbxG+QG14AL94xry4297xpy9Cpf1OoxZ740gHDfrK+gtsy0xabwJmfgtCeii79B6aj0SJeLbd7AAAAAElFTkSuQmCC);
  /* overflow: inherit; */

}
.grainy{
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAElBMVEUAAAD8/vz08vT09vT8+vzs7uxH16TeAAAAAXRSTlMAQObYZgAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAuFJREFUOI0Vk+3NLiEIRG1B8ClAYAsQ2AIEt4D9ePtv5Xp/mZgYJ2fOFJKEfInkVWY2aglmQFkimRTV7MblYyVqD7HXyhKsSuPX12MeDhRHLtGvRG+P+B/S0Vu4OswR9tmvwNPyhdCDbVayJGads/WiUWcjCvCnruTBNHS9gmX2VzVbk7ZvB1gb1hkWFGl+A/n+/FowcO34U/XvKqZ/fHY+6vgRfU92XrOBUbGeeDfQmjWjdrK+frc6FdGReQhfSF5JvR29O2QrfNw1huTwlgsyXLo0u+5So82sgv7tsFZR2nxB6lXiquHrfD8nfYZ9SeT0LiuvSoVrxGY16pCNRZKqvwWsn5OHypPBELzohMCaRaa0ceTHYqe7X/gfJEEtKFbJpWoNqO+aS1cuTykGPpK5Ga48m6L3NefTr013KqYBQu929iP1oQ/7UwSR+i3zqruUmT84qmhzLpxyj7pr9kg7LKvqaXxZmdpn+6o8sHqSqojy02gU3U8q9PnpidiaLks0mbMYz+q2uVXsoBQ8bfURULYxRgZVYCHMv9F4OA7qxT2NPPpvGQ/sTDH2yznKh7E2AcErfcNsaIoN1izzbJiaY63x4QjUFdBSvDCvugPpu5xDny0jzEeuUQbcP1aGT9V90uixngTRLYNEIIZ6yOF1H8tm7rj2JxiefsVy53zGVy3ag5uuPsdufYOzYxLRxngKe7nhx3VAq54pmz/DK9/Q3aDam2Yt3hNXB4HuU87jKNd/CKZn77Qdn5QkXPfqSkhk7hGOXXB+7v09KbBbqdvxGqa0AqfK/atIrL2WXdAgXAJ43Wtwe/aIoacXezeGPMlhDOHDbSfHnaXsL2QzbT82GRwZuezdwcoWzx5pnOnGMUdHuiY7lhdyWzWiHnucLZQxYStMJbtcydHaQ6vtMbe0AcDbxG+QG14AL94xry4297xpy9Cpf1OoxZ740gHDfrK+gtsy0xabwJmfgtCeii79B6aj0SJeLbd7AAAAAElFTkSuQmCC);
}

.shoppingCart{
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 16px;
  font-weight: 300;
  margin-right: 20px;
}

.shoppingCartPic{
  width: 20px;
  margin: 0;
}

.intro{
/* font-weight: 200; */
margin-bottom: 80px;
letter-spacing: 0.5px;
display: flex;
align-items: center;
flex-direction: column;
margin: 0 10 0 0;
justify-content: center;
width: 100%;
}

.intro p{
  font-weight: 300;
  margin: 0%;
}

h2{
font-weight: 400;
}

h4{
  margin-bottom:0;
}

body {
  margin: 0;
  padding: 0;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwBAMAAAClLOS0AAAAElBMVEUAAAD8/vz08vT09vT8+vzs7uxH16TeAAAAAXRSTlMAQObYZgAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAuFJREFUOI0Vk+3NLiEIRG1B8ClAYAsQ2AIEt4D9ePtv5Xp/mZgYJ2fOFJKEfInkVWY2aglmQFkimRTV7MblYyVqD7HXyhKsSuPX12MeDhRHLtGvRG+P+B/S0Vu4OswR9tmvwNPyhdCDbVayJGads/WiUWcjCvCnruTBNHS9gmX2VzVbk7ZvB1gb1hkWFGl+A/n+/FowcO34U/XvKqZ/fHY+6vgRfU92XrOBUbGeeDfQmjWjdrK+frc6FdGReQhfSF5JvR29O2QrfNw1huTwlgsyXLo0u+5So82sgv7tsFZR2nxB6lXiquHrfD8nfYZ9SeT0LiuvSoVrxGY16pCNRZKqvwWsn5OHypPBELzohMCaRaa0ceTHYqe7X/gfJEEtKFbJpWoNqO+aS1cuTykGPpK5Ga48m6L3NefTr013KqYBQu929iP1oQ/7UwSR+i3zqruUmT84qmhzLpxyj7pr9kg7LKvqaXxZmdpn+6o8sHqSqojy02gU3U8q9PnpidiaLks0mbMYz+q2uVXsoBQ8bfURULYxRgZVYCHMv9F4OA7qxT2NPPpvGQ/sTDH2yznKh7E2AcErfcNsaIoN1izzbJiaY63x4QjUFdBSvDCvugPpu5xDny0jzEeuUQbcP1aGT9V90uixngTRLYNEIIZ6yOF1H8tm7rj2JxiefsVy53zGVy3ag5uuPsdufYOzYxLRxngKe7nhx3VAq54pmz/DK9/Q3aDam2Yt3hNXB4HuU87jKNd/CKZn77Qdn5QkXPfqSkhk7hGOXXB+7v09KbBbqdvxGqa0AqfK/atIrL2WXdAgXAJ43Wtwe/aIoacXezeGPMlhDOHDbSfHnaXsL2QzbT82GRwZuezdwcoWzx5pnOnGMUdHuiY7lhdyWzWiHnucLZQxYStMJbtcydHaQ6vtMbe0AcDbxG+QG14AL94xry4297xpy9Cpf1OoxZ740gHDfrK+gtsy0xabwJmfgtCeii79B6aj0SJeLbd7AAAAAElFTkSuQmCC);

}

.sectionbox{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px;
  /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); */
  border-radius: 6px;
  font-size: small;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: 1px solid rgb(199, 199, 199);
  
}

.cartsectionbox{
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 800px;
}

.introText{
  letter-spacing: 0.5px;
  margin-top: 0px;
  margin-right: 20px;
  padding-right: 50px;
  line-height: 1.2;
  font-weight: 300;
  font-size: 16px;
  border-right: 1px solid rgb(110, 110, 110);
  display: flex;
  align-items: baseline;
  flex-direction: column; /* You can adjust the color and thickness as needed */
}

.introText h1 {
  font-size: 40px; /* Adjust the font size as needed */
}

.introText h2{
  font-weight: 500;
}

.rating{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 50px;
}

nav{
  position: sticky;
  top: 0px; 
  margin-bottom: 50px;
  color: "inherit";   
  text-decoration: "none";
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.navServices{
  font-weight: 200;
  margin-left: 20px;
  font-size: 16px;
}

img {
  width: 300px;
  margin: 10px;
}

.header{
  height: 50px;
  background-color: black;
  color: white;
  width: 100%;
  font-weight: 600;
  position: sticky;
  top: 0px; 
  text-align: center;
  letter-spacing: 1px;
}

.offercard{
  width: 220px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin: 20px;
  padding: 20px;
  border: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.5;
  border-radius: 6px;
}

.offercardPrice{
  /* font-weight: 400; */
  margin-top: 10px;
}

.offer-details-textbox{
 height: 180px;
 width: 220px;
 font-size: 16px;
 line-height: 1.2;
 margin-right: 10px;
}

.offer-details-textbox li{
  margin-bottom: 12px;
 }


.card-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items:center;
  justify-content: space-evenly;
  letter-spacing: 0.5px;
  margin: 0px;
  font-weight: 300;
  font-size: 20px;

}

.postpayment{
  display: flex;
  flex-direction: row;
  justify-content: center;
}



.footer{
 font-weight: 300;
 font-size: 16px;
 margin-left: 50px;
 margin-right: 50px;
 width: 80%;
 display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  letter-spacing: 1px;
  
}

.button{
  /* width: 100px; */
  padding: 15px 20px;
  top: .125em;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 16px;
  width: 150px;
  
}

.cartbutton{
  /* width: 10px; */
  /* height: 20px; */
  padding: 8px 12px;
  margin: 0;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 6px;
  font-size: 12px;
}

input[type="file"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: b;
  margin-bottom: 10px;
  /* Add any additional styles as needed */
}

.cartbox{
  display: flex;
  flex-direction: column;
}

.imagebox{
  /* height: 100%; */
  width: 320px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  margin-bottom: 20px;
  border: 5px;
  overflow: hidden;
  border-radius: 6px;
  /* background-color: aquamarine; */
}

.imagebox img {
  border-radius: 6px;
  /* max-width: 100%;
  max-height: 100%; */
}

.ratingbox{
  /* height: 150px; */
  width: 320px;
  padding: 10px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  margin-bottom: 20px;
  border: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 0.5;
  border-radius: 6px;
  /* background-color: aquamarine; */
}

.ratingbox p{
  color: rgb(255, 14, 14);
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
}
.vertical-line {
  border-left: 1px solid black; /* You can adjust the color and thickness as needed */
  height: 500px; /* Adjust the height of the line as needed */
}

.extra-req{
font-size: 12px;
}

.requirements{
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 300;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  margin-bottom: 20px;
}

.checked{
  margin-top: 5px;
  margin-bottom: 5px;
  /* padding-left: 50px; */
  width: 25px;
}

.insta{
  width: 16px;
  top: .125em;
  position: relative;
}

svg {
  top: .125em;
  position: relative;
}


.carousel {
  overflow: hidden;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 450px;
  background-color: #fff;
}

.carousel-item-text {
  font-size: 1.15rem;
  margin: 40px 0 40px 0;
  padding: 0 20px 0 20px;
  white-space: normal;
  color: #617d98;
}
.carousel-img {
  /* width: 250px; */
  padding: 0 0px 0 0px;
}

/* final stage */

.carousel-buttons {
  display: flex;
  justify-content: space-evenly;
}

.button-arrow {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

.indicators {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.indicator-buttons {
  border: none;
  cursor: pointer;
}

.indicators > button {
  margin: 5px;
  background: none;
}

.indicator-symbol {
  color: #898989;
}

.indicator-symbol-active {
  color: #000000;
}

.basictext {
  width: 350px;
  height: 350px;
}

.description{
font-weight: 200;
/* letter-spacing: 1.2; */
line-height: 1.2;
font-size: 16px;
text-align: left;

}

.descriptionBox{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  /* height: 500px; */
  margin-left: 20px;
}

.descriptionBox button{
  margin-right: 20px;
  margin-bottom: 0;
}

.descriptionButtons{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bookingInput{
  margin-top: 10px;
  margin-bottom: 50px;
  min-width: 500px;
  padding: 10px;
  border-radius: 1pxpx;
  /* opacity: 50%; */
  outline: none;
  /* outline: solid #767676 */
}

.forms{
  font-size: 16px;
}

@media (max-width: 1000px) {
  /* Change layout to a single column below 768px */
  .descriptionBox{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* height: 500px; */
    margin-left: 10px;
    margin-right: 10px;
  }

  .offerCardBody{
    font-size: medium;
  }
  .ratingbox{
    /* height: 150px; */
    width: 320px;
    padding: 10px 0px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    margin-bottom: 20px;
    border: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0.5;
    border-radius: 6px;
    /* background-color: aquamarine; */
  }
  .intro{
    /* font-weight: 200; */
    margin-bottom: 25px;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 10 0 0;
    justify-content: center;
    display: none;
    align-content: center;
    /* margin: 0 25 0 0; */
    }
  .intro p{
    align-content: center;
  }

  .requirements{
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 6px;
    font-weight: 300;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    margin-bottom: 20px;
  }
  
  .rating{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 0px;
  }

  .postpayment{
    flex-direction: column;
  }
  .nav{
    overflow: scroll;
  }
  .introText{
    letter-spacing: 0.5px;
    margin:30px;
    padding-right: 0px;
    line-height: 1;
    font-weight: 300;
    font-size: 12px;
    border-right: 0px solid rgb(110, 110, 110);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; /* You can adjust the color and thickness as needed */
  }
  .introText h1{
    text-align: center;
    font-size: 35px;
  }
  .basictext {
    width: 350px;
    height: 300px;
  }
  .sectionbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 5px;
    /* box-shadow: 2 2px 2px rgba(0, 0, 0, 0.25); */
    outline: 1px solid rgb(199, 199, 199);
    border-radius: 6px;
    font-size: small;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sectionbox h1{
    font-size: xx-large;
  }

  .card-container {
      flex-direction: column;
      align-items: flex-end;
      flex-wrap: wrap;
  }

  .card {
      width: 350px; /* Full width cards in a single column */
      margin-bottom: 10px ; /* Adjust spacing between cards */
  }
  .imagebox{
    /* height: 100%; */
    width: 320px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.25);
    margin-top: 20px;
    margin-bottom: 20px;
    border: 5px;
    overflow: hidden;
    border-radius: 6px;
    /* background-color: aquamarine; */
  }
  .footer{
    font-weight: 300;
    font-size: 12px;
    margin-left: 50px;
    margin-right: 50px;
    width: 80%;
    display: flex;
     align-items: center;
     justify-content: center;
     margin-top: 50px;
     letter-spacing: 0.4px;
   }
}
